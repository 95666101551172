// extracted by mini-css-extract-plugin
export var backgroundImage = "VideoText-module--backgroundImage--87867";
export var button = "VideoText-module--button--c13d1";
export var content = "VideoText-module--content--8ed5c";
export var iframe = "VideoText-module--iframe--0121d";
export var image = "VideoText-module--image--99509";
export var imageContainer = "VideoText-module--imageContainer--07901";
export var imageText = "VideoText-module--imageText--bf32f";
export var info = "VideoText-module--info--64dd4";
export var sectionWrapper = "VideoText-module--sectionWrapper--f32aa";
export var text = "VideoText-module--text--7fa3b";
export var title = "VideoText-module--title--b9de5";
export var wrapperdesktop = "VideoText-module--wrapperdesktop--89bfd";
export var wrappermobile = "VideoText-module--wrappermobile--d2442";