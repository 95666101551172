import React from 'react';
import parse from 'html-react-parser';
import * as styles from './VideoText.module.scss';
import clsx from 'clsx';
import Title from '../Title/Title';
import Button from '../Button/Button';
import { getURL } from '../../scripts/utils';
import { useMediaQuery } from '../../hooks/media-query';

export default function ImageText({ data, className }) {
  const mediaQueryMax = useMediaQuery('max');

  function Image({image, className, bgImage}) {
    if(image && image.url) {
      return (
        <>
          <div className={styles.backgroundImage} style={{backgroundImage: bgImage ? `url(${bgImage})` : 'none'}}>
          </div>
          <div className={clsx(styles.image, className)}>
            <img alt={image.alt} src={image.url} />
          </div>
        </>
      )
    }
  }

  function Headline({text}) {
    if(text) {
      return (
        <Title className={clsx(data.css_class && data.css_class.text)}>{parse(text)}</Title>
      )
    }
  }

  function Text({text}) {
    if(text) {
      return (
        <div className={styles.text}>{parse(text)}</div>
      )
    }
  }

  function Cta({cta, text}) {
    if(text && text.text) {
      const link = getURL(cta);

      return(
        <Button key={text.text} url={cta.uid} target={cta.target} className={styles.button}>
          {text.text}
        </Button>
      )
    }
  }

  return (
    <section
      className={
        clsx(
          className,
          styles.imageText,
          data.css_class && data.css_class.text,
          data.background_image && data.background_image.url && 'bg-image'
        )}
      style={{height: mediaQueryMax && data.height_max && data.height_max.text ? `${data.height_max.text}px` : 'auto'
      }
    }>
      <div className={clsx('section-wrapper', styles.sectionWrapper)}>
        <div className={styles.imageContainer}>
          <div className={styles.wrapperdesktop}>
            <iframe src="https://player.vimeo.com/video/906869731?h=5743e8695c?muted=1&autoplay=1"></iframe>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <Headline text={data.headline.html}/>
            {data.css_class && data.css_class.text.includes('headline-image') && <Image image={data.image} className={clsx(data.image_css_class && data.image_css_class.text, 'mobile-image')}/>}
          </div>
          <div className={styles.info}>
            <Text text={data.paragraph.html} />
                <div className={styles.wrappermobile}>
                    <iframe src="https://player.vimeo.com/video/906869731?h=5743e8695c?muted=1&autoplay=1"></iframe>              
                </div>
            <Cta cta={data.cta_url} text={data.cta_text} />
          </div>
        </div>
      </div>
    </section>
  )
}
