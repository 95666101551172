
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/';
import ImageText from '../components/ImageText/ImageText';
import VideoText from '../components/VideoText/VideoText';
import SearchEngineOpt from '../components/SearchEngineOpt/SearchEngineOpt';

  function SectionSwitcher({index,section}) {
    if(index === 0) {
      return (
        <VideoText key={index} data={section} />
      )
    } else {
      return (
        <ImageText key={index} data={section} />
      )        
    }
  }

const IndexPage = ({data}) => {
  return (
    <Layout>
      <div className="page">
        {data.page.data.sections.map((section, index) => (
            <SectionSwitcher index={index} section={section} />
        ))}
      </div>
    </Layout>
  )}

export default IndexPage

export const Head = ({ data }) => (
  <SearchEngineOpt
    title={data.page.seo.title && data.page.seo.title.text}
    description={data.page.seo.description && data.page.seo.description.text}
    image={data.page.seo.image && data.page.seo.image.url}
    keywords={''} />
)

export const IndexQuery = graphql`
query {
  page: prismicHomepage {
        uid
        seo: data {
          title {
            text
          }
          description {
            text
          }
          image {
            url
          }
        }
        data {
          sections {
            headline {
              html
            }
            paragraph {
              html
            }
            image {
              alt
              url
            }
            image_css_class {
              text
            }
            background_image {
              url
            }
            cta_text {
              text
            }
            cta_url {
              uid
              target
              slug
              link_type
              url
            }
            css_class {
              text
            }
          }                    
        }
      }
    }
`
